<template>
  <v-container style="max-width:1200px;">
    <v-row align="center" justify="center">
      <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8"
        >
        <v-card flat style="border-radius:8px;border: 1px solid #AD145733;">
          <v-card-title
            class="py-1 pl-0"
            style="font-size:24px;color:#AD1457;border-bottom:1px solid #AD1457;"
          >
            <v-btn icon color="#AD1457"
                @click="goBack" class="mr-2">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
               {{program && program._id?'Thông Tin Chương Trình':''}}
               {{$route.params.program_id=='0'?'Tạo Chương Trình Mới':''}}
            <v-spacer />
          </v-card-title>
          <v-card-text 
            v-if="displayCarAfterFilter"
            class="pa-4 px-6"
            :class="{'px-2': $vuetify.breakpoint.smAndDown}">
            <div
              style="width:100%;font-size:15px;"
            >

              <!-- Room name -->
              <div style="mt-2">
                Tên Chương Trình *:
              </div>
              <div>
                <v-text-field
                ref="name"
                  flat
                  dense
                  v-model="program.name"
                  :rules="[rules.required]"
                  color="teal"
                  :clearable = "$vuetify.breakpoint.smAndDown"
                  outlined filled
                  single-line
                  hide-details
                  placeholder=""
                ></v-text-field>
              </div>

              <div class="mt-3">
                Trạng thái:
                <span class="ml-8" style="font-size:16px; font-weight:600; color:#666666;"> 
                  {{statusResolve(program)}}
                </span>
              </div>

              <div class="mt-3">
                Mô tả:
              </div>
              <div class="description">
                <v-textarea
                  flat
                  dense
                  v-model="program.description"
                  :rules="[]"
                  color="teal"
                  :clearable = "$vuetify.breakpoint.smAndDown"
                  outlined
                  filled auto-grow
                  hide-details
                ></v-textarea>
              </div>
            </div>
          </v-card-text>
          <v-card-actions 
            v-if="program"
            class="pr-4">
            <v-spacer/>
            <v-btn
                class="my-1"
                style="min-width:160px;"
                color="#AD1457" dark
                :loading="saveLoading"
                @click.stop="callSaveProgram">
                Lưu
              </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      token: "token",
    }),
    displayCarAfterFilter() {
      let result = null
      if (!this.program) return null
      result = JSON.parse(JSON.stringify(this.program))
      return result
    },
  },
  watch:{
    '$route.params'(){
      // console.log('watch: ',this.$route.params.program_id)
      this.onPageLoading()
    }
  },
  data() {
    return {
      roles: ['program-manager', 'admin'],
      program: null,
      saveLoading: false,
      dataBeforeSave: null,
      showFullImage: false,
      imgFullScreen: "",
      rules: {
        required: (value) => !!value || "* Required",
      },
    };
  },
  methods: {
    goBack () {
      this.$router.push({path: '/programs'})
    },
    statusResolve(program) {
      if(program.status == 'RUNNING') {
         return "Đang quay"
      }
      if(program.status == 'END') {
         return "Đã kết thúc"
      }
      return "Chưa xác định"
    },
    createProgramCode (name) {
      return name.normalize("NFD")
                                 .replace(/[\u0300-\u036f]/g, "")
                                 .replace(/đ/g, "d")
                                 .replace(/Đ/g, "D")
                                 .replace(/\s+/g, '-')
                                 .toUpperCase() + "-"+ new Date().getTime()
    },
    callCreateProgram () {
      let self = this
      this.program.code = "MCV-PROGRAM-" + self.createProgramCode(self.program.name) + '-' + Date.now();
      this.program.type = 'PROGRAM'
      this.saveLoading = true

      this.axios
        .post(this.$root.apiAssetMana + "/programs",
              self.program,
              { headers: { "x-auth": self.token } })
        .then((res) => {
          if (res.data.status === "OK") {
            alert("Tạo chương trình " + self.program.name + " thành công!");
            self.$router.push({path: '/programs-detail/' + res.data.content._id})
          } else {
            alert("Lỗi: " + res.data.message);
          }
        })
        .catch((err) => {
          alert("Lỗi: " + err.message);
        }).finally(()  => {
          self.saveLoading = false
        })
    },
    callSaveProgram() {
      if (!this.program) return

      // if (!this.program.code || this.program.code.length <= 0) {
      //   alert("Mã xe không được để trống!");
      //   return;
      // }
      if (!this.program.name || this.program.name.length <= 0) {
        alert("Tên chương trình không được để trống!");
        return;
      }

      if(!this.program._id) {
        this.callCreateProgram()
        return
      }
      this.saveLoading = true

      let self = this
      this.axios
        .post(this.$root.apiAssetMana + "/programs", self.program, {
          headers: {
            "x-auth": this.token,
          },
        })
        .then((response) => {
          if (response.data.status == "OK") {
            self.program = response.data.content;
            alert("Cập nhật thành công!");
          } else {
            alert("Lỗi! " + response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Lỗi! " + err.message);
        }).finally(()  => {
          self.saveLoading = false
        })
    },
    fetchProgram() {
      let self = this
      console.log(`fetchProgram ${this.$route.params.program_id}`)
      self.axios
        .get(
          self.$root.apiAssetMana +
            "/programs/" +
            this.$route.params.program_id,
          { headers: { "x-auth": this.token }}
        )
        .then((res) => {
            console.log(res.data)
            if (res.data.status == "OK") {
               self.program = res.data.content
               console.log(self.program)
            } else {
               console.log(res.data.message);
            }
        })
        .catch((err) => {
          console.log(err)
        });
    },
    onPageLoading () {
       if(this.$route.params.program_id){
        if(this.$route.params.program_id == '0') {
          this.program = {
            name: '',
            description: '',
            code: '',
            type: 'program',
            status: 'RUNNING',
            enable: true
          } 
          console.log("new program:", this.program)
          this.$forceUpdate();
        } else {
          this.fetchProgram()
        }
      }
    }
  },
  created () {
    this.$nextTick(() => {
      this.onPageLoading()
    })
  },
};
</script>

<style>
  .description textarea {
    margin-bottom: 10px !important;
    margin-right: 10px !important;
    line-height: 1.35;
    font-size: 16px;
  }
</style>
